.sdg-panel-image {
	float: left;
	width: 50px;
	margin-right: 0.5rem;
	margin-top: 0.5rem;
	border-radius: 4px;
	opacity: 0.6;
}

.inactive {
	filter: grayscale(100%);
	opacity: 0.4;
}
